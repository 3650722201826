.mb {
  position: fixed;
  top: 20px;
  right: 20px;
  // transform: translateX(-50%);

  color: white;
  padding: 10px 20px;
  text-align: center;
  z-index: 25;
  border-radius: 5px;
 
  text-transform: capitalize;
  backdrop-filter: blur(5px);
}


.ErrorMsg{
  background-color: rgba(crimson,0.3);
}

.SuccessMsg {
  background-color: rgba(#129b7b,0.3);
}

.InfoMsg {
  background-color: rgba(#126d9b,0.3);
}

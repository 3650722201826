$ruler: 16px;
$color-red: #ae1100;
$color-bg: #ebecf0;

$color-green: #1baa8b55;
$dark-white: #41435766;
$dark-shadow: #16161e;

.Neu-btn {
  display: inline-block;
  min-width: 120px;
  width: auto;
  margin-top: 30px;
  height: 42px;
  border-radius: 25px;
  outline: none;
  border: none;
  background-color: transparent;
  background-size: 200%;
  font-size: 1rem;
  padding: 2px 20px;
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 1rem 7px;
  cursor: pointer;
  transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: -2px -2px 10px var(--shadow-sec),
    5px 5px 10px var(--shadow-pri);

  &:hover {
    box-shadow: -2px -2px 3px var(--shadow-sec),
      2px 2px 5px var(--shadow-pri);
    color: #777;
  }

  &:active {
    box-shadow: inset 1px 1px 2px var(--shadow-sec),
      inset -1px -1px 2px var(--shadow-pri);
  }
}

.Neu-btn.disabled {
  color: rgb(112, 108, 108) !important;
}

.Neu-btn[theme="dark"] {
  background-color: #1a1b26;
  color: white;
  box-shadow: -5px -5px 10px #41435722, -3px -3px 5px $dark-white,
    10px 10px 15px $dark-shadow;

  &:hover {
    box-shadow: -2px -2px 5px $dark-white, 2px 2px 5px $dark-shadow;
    color: #777;
  }

  &:active {
    box-shadow: inset 1px 1px 2px $dark-shadow, inset -1px -1px 2px $dark-white;
  }
}

.mb-3{
    text-align: left;
}

.form-control{
    background-color:var(--main-bg-color);
    color:var(--text-color);
}

.form-control:active{
    background-color:var(--main-bg-color)
}

.form-check{
    display: inline-block;
    margin:5px;
}

.form-label{
    color:var(--theme-color);
    font-weight:bold;
}


.input-container {
    position: relative;
   width: 100%;
   display:flex;
   column-gap: 0.6rem;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
  }

  .input-container div [type="text"] {
    position: absolute;
    width: 360px;
    height: 65px;
    color:#3982ff!important;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
    letter-spacing: 2px;
    padding: 0px 45px 0px 18px;
    caret-color: #000;
    background: transparent;
    z-index: 5;
  }

  .input-container [type="text"]::placeholder {
    color: rgba(34, 34, 34, 0.55);
  }

  .input-container .suggestion-container {
    position: absolute;
    background-color: transparent;
    border:none;
    outline: none;
    left: 25px;
    top: 16px;
    display: flex;
    align-items: center;
    color: #8f39ff;
    /* font-family: 'Quicksand', sans-serif;
    font-size: 18px;
    letter-spacing: 2px;
    padding: 0px 45px 0px 18px; */
    pointer-events: none;
    z-index: 3;
  }

  :disabled {
    background-color:var(--body-background) !important; 
    color:grey;
    border:1px solid grey;

}


  @media(max-width:767px){
    .input-container .suggestion-container {
        position: absolute;
        left: 25px;
        top: calc(40px + 16px);
        display: flex;
        align-items: center;
        color: #8f39ff;
        background-color:var(--background-color);
        /* font-family: 'Quicksand', sans-serif;
        font-size: 18px;
        letter-spacing: 2px;
        padding: 0px 45px 0px 18px; */
        pointer-events: none;
        z-index: 3;
      }
  }



/* ######################################### BLUE DARK THEME ########################################## */
[data-theme="light"] {
  --body-background: #16161e;
  --theme-color-sec: #1e202e;
  --theme-color: rgb(56, 108, 253);
  --text-color: #eee;
  --shadow-sec: rgba(36, 42, 49, 0.4);
  --shadow-pri: rgb(15, 17, 22);
}

/* ######################################### ORANGE LIGHT THEME ########################################## */

[data-theme="orange-light"] {
  --body-background: #feece6;
  --theme-color-sec: #ffdcb3;
  --theme-color: rgb(253, 146, 57);
  --text-color: rgb(44, 44, 44);
  --shadow-sec: rgb(255, 255, 255);
  --shadow-pri: rgb(255, 206, 197);
}

/* ######################################### BLUE LIGHT THEME ########################################## */

[data-theme="blue-light"] {
  --body-background: #e6eefe;
  --theme-color-sec: #b3cbff;
  --theme-color: rgb(101, 141, 252);
  --theme-color-dark: rgb(57, 76, 128);

  --text-color: rgb(44, 44, 44);
  --shadow-sec: rgb(255, 255, 255);
  --shadow-pri: rgb(173, 194, 240);
}
[data-theme="blue-dark"] {
  --body-background: hsl(225, 40%, 20%);
  --theme-color-sec: hsl(225, 40%, 30%);
  --theme-color: rgb(101, 141, 252);
  --theme-color-dark: #16161E;
  
  --text-color: rgb(240, 232, 232);
  --shadow-sec: hsl(225, 41%, 25%);
  --shadow-pri: hsl(226, 39%, 17%);
}
[data-theme="purple-light"] {
  --body-background: #f4e6fe;
  --theme-color-sec: #d0b3ff;
  --theme-color: rgb(179, 101, 252);
  --text-color: rgb(44, 44, 44);
  --shadow-sec: rgb(255, 255, 255);
  --shadow-pri: rgb(208, 173, 240);
}

[data-theme="purple-dark"] {
  --body-background: #371B58;
  --theme-color-sec: #4C3575;
  --theme-color: #7858A6;
  --text-color: rgb(240, 232, 232);
  --shadow-sec: hsl(280, 45%, 29%);
  --shadow-pri: hsl(288, 40%, 15%);
}

[data-theme="yellow-light"] {
  --body-background: #fdfdec;
  --theme-color-sec: #fffeb3;
  --theme-color: #fbcf26;
  --text-color: rgb(44, 44, 44);
  --shadow-sec: rgb(255, 255, 255);
  --shadow-pri: rgb(226, 226, 202);
}

[data-theme="green-light"] {
  --body-background: #E0DECA;
  --theme-color-sec: #FCF9C6;
  --theme-color: #9EB23B;
  --text-color: rgb(44, 44, 44);
  --shadow-sec: hsl(74, 79%, 89%);
  --shadow-pri: hsl(72, 20%, 71%);
}
[data-theme="grey-pink"] {
  --body-background: #DAEAF1;
  --theme-color-sec: #FFE6E6;
  --theme-color: #F2D1D1;
  --text-color: rgb(44, 44, 44);
  --shadow-sec: hsl(198, 31%, 94%);
  --shadow-pri: #C6DCE4;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Comfortaa", Roboto;
}

:target{
  color:yellow;
}



body {
  /* background-color: var(--body-background) !important; */
  overflow-x: hidden;
  padding: 10px;
  overflow:hidden;
  /* background:
radial-gradient(var(--theme-color-sec) 1%, transparent 10%) 0.1px 0.1px,    
radial-gradient(var(--theme-color-sec) 1%, transparent 10%) 8px 8px,
radial-gradient(rgba(255,255,255,.01) 1%, transparent 11%) 0.1px 0.1px,
radial-gradient(rgba(255,255,255,.01) 1%, transparent 11%) 8px 8.2px; */
background-color:var(--body-background) !important;
background-size:16px 16px;
color:var(--text-color);
font-size: "0.7rem";
font-family: "Helvetica Neue",Helvetica,Arial, Georgia, 'Times New Roman', Times, serif, sans-serif;

}


input{
  color:var(--text-color);
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--theme-color);
  border-radius: 10px;
}


table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: auto;
}

td, th {
  border: 2px solid #dddddd;
  text-align: left;
  padding: 8px;
}

td:nth-child(odd) {
  text-align: left;
}

.th-text {
  color: var(--text-color);
}

.sidebar-wrapper {
  width: 240px;
}


.navbar-inner {
  /* background: var( --theme-color-sec); */
  top: 30px;
  position: absolute;
  left: 120px;
  width: calc(100vw - 140px);
  height: 100%;
}

.navbar-inner .nav-container {
  background: var(--theme-color-sec);
  height: 50px;
  border-radius: 10px;
  margin-bottom: 0.8rem;
}

.navbar-inner .container2 {
  background: var(--theme-color-sec);
  height: 30vh;
  border-radius: 10px;
  margin-bottom: 0.8rem;
}
.navbar-inner .container3 {
  background: var(--theme-color-sec);
  height: 20vh;
  border-radius: 10px;
  margin-bottom: 0.8rem;
}
.navbar-inner .container4 {
  background: var(--theme-color-sec);
  height: 32vh;
  border-radius: 10px;
  margin-bottom: 0.8rem;
}

.main-content{
  
  background-blend-mode: overlay;
  opacity: 0.8;
  border-radius:10px;
  padding:1rem;
  
  /* min-height: 100vh; */
}

/* .ee_card {
  min-width: 200px;
  min-height: 150px;
  background-color: var(--theme-color);
  padding: 20px;
  margin: 20px;
  border-radius: 20px;
  position: relative;
  top: 20px;
  width: 15rem;
  border: 2px solid #fff3;
  margin-bottom: 30px;
} */

.ee-card {
  min-width: 260px;
  min-height: 120px;
  background-color: transparent;
  box-shadow: -5px -5px 10px var(--shadow-sec), 5px 5px 10px var(--shadow-pri);
  padding: 15px;
  border-radius: 10px;
  color: var(--text-color);
  overflow: hidden;
  margin-bottom: 25px;
}

/* .ee-card::before {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background-color: rgba(255, 255, 255, 0.333);
  z-index: 5;
  filter: blur(10px);
} */

.page-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ee-card .title {
  /* background-color: var( --theme-color-sec); */
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.5rem;
  margin-bottom: 40px;
  color: #666;
  text-align: center;
}

.ee-textbox {
  padding: 15px 10px;
  background-color: transparent;
  border: none;
  box-shadow: inset -5px -5px 10px var(--shadow-sec),
    inset 2px 2px 10px var(--shadow-pri);
  border-radius: 5px;
  width: 100%;
  margin-bottom: 20px;
  outline: none;
}

.ee-textbox:focus {
  box-shadow: inset -5px -5px 5px var(--shadow-sec),
    inset 2px 2px 5px var(--shadow-pri);
}

.ee-button {
  padding: 10px 15px;
  background-color: var(--theme-color);
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: auto;
  color: white;
  border: 5px solid var(--body-background);
  box-shadow: -5px -5px 10px var(--shadow-sec), 5px 5px 10px var(--shadow-pri);
  outline: none;
}

.ee-button:active {
  box-shadow: none;
}

.logo {
  background-color: var(--theme-color);
  display: inline-block;
  padding: 10px 15px;
  border: 2px dashed var(--body-background);
  color: white;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  font-size: 12px;
}

.content-wrapper {
  position: absolute;
  top: 30px;
  right: 0px;
  text-align: right;
  width: calc(100% - 100px);
  height: 100vh;
  overflow-y: auto;
  transition:all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.content-wrapper .content {
  position: relative;
  right: 0;
}

.sidebar-wrapper:hover ~ .content-wrapper {
  width: calc(100% - 260px);
  /* transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    background-color: var(--body-background);
  }
  
  #sidebar-nav::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #7b83eb88;
  }
  
  #sidebar-nav::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
  }
  
  #sidebar-nav::-webkit-scrollbar-thumb {
    background-color: var(--theme-color);
  }
  
  .navigation {
    position: absolute;
    width: 83px;
    background: var(--theme-color-sec);
    border-right: 10px solid var(--theme-color);
    height: calc(100vh - 30px);
    border-left: 10px solid var(--theme-color-sec);
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .navigation ul {
    position: absolute;
    top: calc(40vh / 2);
    left: 0;
    width: 100%;
    margin: auto;
    padding: 0 0 0 5px;
    outline: none;
  }
  
  .sidebar-wrapper:hover .navigation,
  .sidebar-wrapper:hover .logo-container {
    width: 243px;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .navigation ul li {
    position: relative;
    list-style: none;
    width: 100%;
    outline: none;
  }
  
  .navigation ul li:hover a {
    color: var(--text-color);
    filter: saturate(1);
    font-weight: bolder;
    z-index: 10;
  }
  
  /* .navigation ul li a.active:hover a {
    color: var( --theme-color-sec);
  } */
  .navigation ul li a {
    position: relative;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: var(--text-color);
    outline: none;
  }
  .navigation ul li a .icon {
    position: relative;
    display: block;
    min-width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
  
  .navigation ul li a .icon ion-icon {
    position: relative;
    font-size: 1.5em;
    z-index: 1;
  }
  
  .navigation ul li a .icon i {
    position: relative;
    font-size: 1.5em;
    z-index: 1;
  }
  .navigation ul li a .title {
    position: relative;
    display: block;
    padding-left: 5px;
    height: 60px;
    line-height: 60px;
    white-space: nowrap;
    z-index: 20;
    font-size:1rem;
  }
  
  .navigation ul li a.active {
    background: var(--theme-color);
    border-radius: 32px 0 0 32px;
    box-shadow: -1px 2px 2px var(--shadow-pri);
  }
  
  .navigation ul li a.active a {
    color: white;
    font-weight: bold;
  }
  
  .navigation:hover ul li a.active {
    border-radius: 10px 0 0 10px;
  }
  
  .navigation ul li a.active::before{
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    top: -40px;
    right: 0;
    /* background: var(--theme-color-sec); */
    border-radius: 0 0 50% 0;
    box-shadow: 20px 20px 0 var(--theme-color);
  }
  .navigation ul li a.active::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: -40px;
    right: 0;
    /* background: var(--theme-color-sec); */
    border-radius: 0  50% 0 0;
    box-shadow: 20px -20px 0 var(--theme-color),inset -0.5px 1px 1px var(--shadow-pri);
  }

  /* .navigation ul li a.active{
    padding: 0 5px;
  } */
  

  @media screen and (max-width: 900px) {

    body{
      font-family: "Helvetica Neue",Helvetica,Arial, Georgia, 'Times New Roman', Times, serif, sans-serif;
      font-family: "comfortaa", "century gothic";
    }

    .navigation {
      width: 100%;
      height: 80px;
      position: absolute;
      bottom:0;
      border-right:none;
      border-left:none;
      border-top: 5px solid var(--theme-color);
      transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    .navigation ul {
      top:0;  
      float: left;
      display:flex;
      padding:0;
    }
    


    .content-wrapper{
      width: 100%;
      height:90%;

    }

    .sidebar-wrapper{
      display:flex;
      bottom: 34px;
      height: 0;
      position: absolute;
      bottom: 40px;
      width: 100%;
    }

    .navigation ul li a .title{
      display:none;
    }
    .navigation ul li a, .navigation ul li a .icon{
      z-index: 20;
    }
    /* .navigation ul li a.active::before,
    .navigation ul li a.active::after {
      display: none;
    } */

    .navigation ul li a.active::before{
      content: "";
      position: absolute;
      width: 40px;
      height: 30px;
      left: -40px;
      top: 0;
      /* background: #eee; */
      border-radius: 0  40% 0 0;
      box-shadow: 20px 0px 0 var(--theme-color);
      z-index: -1;
      border:1px solid var(--theme-color-sec);
    }
    .navigation ul li a.active::after {
      content: "";
      position: absolute;
      width: 40px;
      height: 30px;
      right: -40px;
      top: 0;
      /* background: #eee; */
      border-radius: 40%  0 0 0;
      box-shadow: 0px -20px 0 var(--theme-color);
      z-index: -1;
      border:1px solid var(--theme-color-sec);
    }
    
    .navigation ul li a.active{
      border-radius:0 0  18px 18px;
    }

    .navigation:hover ul li a.active {
      border-radius: 0 0 20px 20px ;
    }

    .navigation ul li a {
      justify-content:center;
    }
    

    .sidebar-wrapper:hover ~ .content-wrapper{
      width:100%;
    }

    .sidebar-wrapper:hover ~ .navigate{
      width:100%;
    }


    .sidebar-wrapper:hover .navigation,
  .sidebar-wrapper:hover .logo-container {
    width: 100%;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  }



/*   
  @media screen and (min-width: 800px) {
    
    .navigation ul li a.active .title{
      display:block;
    }
    .navigation ul li a.active{
      padding: 0 5px;
    }
    
    .navigation ul li a .icon{
     
      transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    
  } */
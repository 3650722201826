.spinner {
    position: relative;
    width: 22.4px;
    height: 22.4px;
    display: flex;
    /* width: 20em; */
    /* height: 100vh; */
    margin: 4rem auto;
 }
 
 .spinner::before,
 .spinner::after {
    --radius: 250;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    animation: spinner-w7nm60 2s infinite linear;
    background: var(--theme-color);
    border-radius: 50%;
 }
 
 .spinner::before {
    --radius: -250;
    background: var(--theme-color-sec);
 }
 
 @keyframes spinner-w7nm60 {
    0% {
       transform: scale(1) rotate(0deg) translateY(calc(var(--radius) * 1%));
    }
 
    50% {
       transform: scale(0.5) rotate(1440deg) translateY(0);
    }
 
    100% {
       transform: scale(1) rotate(2920deg) translateY(calc(var(--radius) * 1%));
    }
 }
.neu-shadow {
  box-shadow: 5px 5px 8px var(--shadow-pri),
    -5px -5px 12px var(--shadow-sec);
}

._card{
  background-color:var(--body-background);
  min-width:100px;
  min-height:100px;
  border-radius:10px;
  padding:15px;
  margin:15px 5px;
  text-align:left;
  color: var(--text-color);
  
  overflow: hidden;
}

.heading{
  margin-bottom:20px;
}

.card-body{
  text-align:center;
  overflow:auto;
  max-height: 735px;
}

$ruler: 5px;
$color-red: #ae1100;
$color-bg: #ebecf0;
$color-shadow: #98a8e288;
$color-white: #ffffff;
$color-green: #1baa8b55;
$dark-white: #1e202e;
$dark-shadow: #16161e;
$invalid-shadow: #ff3460;

.ee_input {
  border: none;
  outline: none;
  margin-right: $ruler/2;
  box-shadow: inset 2px 2px 5px var(--shadow-pri),
    inset -2px -2px 5px var(--shadow-sec);
  width: 100%;
  margin: $ruler;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;
  padding: 5px 20px !important;
  border-radius: 8px;
  height: 45px;
  // font-family: "Poppins", sans-serif;
  background-color: transparent;
  z-index: 5;
  font-weight: 100;
  // color: #777;

  &:focus {
    box-shadow: inset 1px 1px 2px var(--shadow-pri),
    inset -1px -1px 2px var(--shadow-sec);
  }
  &.Invalid:focus {
    box-shadow: inset 1px 1px 2px var(--box-shadow),
      inset -1px -1px 2px var(--box-shadow-inv);
  }
}

input.ee_input[theme="dark"] {
  background-color: transparent;
  color: white;

  box-shadow: inset 5px 5px 5px $dark-shadow,
    inset -5px -5px 10px var(--box-shadow-inv);
  &:focus {
    box-shadow: inset 1px 1px 2px $dark-shadow,
      inset -1px -1px 2px var(--box-shadow-inv);
  }
}

@keyframes shadowRotationLight {
  0% {
    box-shadow: inset 1px 2px 4px $invalid-shadow,
      inset -2px -2px 4px var(--box-shadow-inv);
  }
  25% {
    box-shadow: inset 0 -2px 4px $invalid-shadow,
      inset 0 2px 4px var(--box-shadow-inv);
  }
  50% {
    box-shadow: inset -1px -2px 4px $invalid-shadow,
      inset 2px 2px 4px var(--box-shadow-inv);
  }
  75% {
    box-shadow: inset 1px 0 4px $invalid-shadow,
      inset -2px 0 4px var(--box-shadow-inv);
  }
  100% {
    box-shadow: inset 1px 2px 4px $invalid-shadow,
      inset -2px -2px 4px var(--box-shadow-inv);
  }
}
@keyframes shadowRotationDark {
  0% {
    box-shadow: inset 1px 2px 4px $invalid-shadow,
      inset -2px -2px 4px $dark-shadow;
  }
  25% {
    box-shadow: inset 0 -2px 4px $invalid-shadow, inset 0 2px 4px $dark-shadow;
  }
  50% {
    box-shadow: inset -1px -2px 4px $invalid-shadow,
      inset 2px 2px 4px $dark-shadow;
  }
  75% {
    box-shadow: inset 1px 0 4px $invalid-shadow, inset -2px 0 4px $dark-shadow;
  }
  100% {
    box-shadow: inset 1px 2px 4px $invalid-shadow,
      inset -2px -2px 4px $dark-shadow;
  }
}

.Invalid {
  box-shadow: inset 1px 2px 4px $invalid-shadow,
    inset -5px -5px 10px var(--box-shadow-inv);
  animation: 0.6s shadowRotationLight 1 normal;
  //   border: 1px solid red;
  //   background-color: #fda49a;
}

.Invalid[theme="dark"] {
  // animation: 0.6s shadowRotationDark 1 normal;
  box-shadow: inset -2px -2px 5px $invalid-shadow,
    inset 2px 2px 4px $dark-shadow !important;
  //   border: 1px solid red;
  //   background-color: #fda49a;
}

$ruler: 16px;
$ruler: 16px;
$color-red: #ae1100;
$color-bg: #ebecf0;
$color-shadow: #98a8e288;
$color-white: #fff;
$color-green: #1baa8b55;
$background-color: #e6effe22;
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.Wavebg {
  position: fixed;
  height: 100%;
  //width: 50%;
  top: 0;
  left: 0;
  z-index: 1;
}

.WaveMob {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: none;
}

.main-container {
  
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // grid-template-columns: repeat(1, 1fr);
  // grid-gap: 2rem;
  padding: 0 2rem;
  background-color: var(--background);
}

.errorMessage {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: crimson;
  color: white;
  padding: 10px 20px;
  text-align: center;
  z-index: 35;
  border-radius: 5px;
  opacity: 0.8;
  text-transform: capitalize;
}

.successMessage {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #129b7b;
  color: white;
  padding: 10px 20px;
  text-align: center;
  z-index: 35;
  border-radius: 5px;
  opacity: 0.8;
  text-transform: capitalize;
}

.form {
  color: #22bd99;
  width: 360px;
  text-align: center;
  align-items: center;
  background: linear-gradient(-135deg, #7293f722 0%, transparent 80%);
  padding: 20px;
  // box-shadow: -5px -5px 20px #ffffff, 5px 5px 20px $color-shadow;
  border-radius: 20px;
  backdrop-filter: blur(4px);
  border: 2px solid #fff5;
  // box-shadow:inset 5px 5px 35px var(--box-shadow-inv),
  //  inset -5px -5px 35px var(--box-shadow-inv);
}

.validationErrorMsg {
  color: crimson;
  font-family: monospace;
}

@supports not (backdrop-filter: blur()) {
  .form {
    float: right;
    // background: linear-gradient(180deg, rgba(213, 235, 255, 0.967), #e6effeee);
    background: #e6effe;
    border: none;
    box-shadow: -5px -5px 20px $color-white, 5px 5px 20px $color-shadow;
  }
  .bgCir {
    display: none;
  }
  .bgCir2 {
    display: none;
  }
  .bgCir3 {
    display: none;
  }
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif !important;
  }
}

.side-image {
  z-index: 2;
}

.side-image img {
  position: relative;
  top: 30%;
  left: 12%;
  width: 550px;
  z-index: 2;
}

.p-image {
  width: 200px !important;
}
.login-container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index: 10;
}

.login-container .input-div {
  position: relative;
  display: grid;
  grid-template-columns: 7% 93%;
  margin: 25px 0;
  padding: 5px 0;
  border-bottom: 2px solid #d9d9d9;
}

.i {
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  bottom: -14px;

  // background-color: #38d39f;
  // padding: 10px;
  // border-radius: 10px 0 0 10px;
}

.i i {
  transition: 0.3s;
}

.bgCir,.bgCir2,.bgCir3 {
  position: absolute;
  top: 10%;
  right: 5%;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: linear-gradient(120deg, #242c58, #015d65);
  box-shadow: 5px 5px 20px var(--box-shadow), inset 20px 20px 30px #b3ffed88;
}

// .bgCir::after {
//   content: "";
//   position: absolute;
//   top: 10%;
//   left: 20%;
//   width: 40px;
//   height: 20px;
//   background-color: #fff5;
//   z-index: 20;
//   display: block;
//   border-radius: 50%;
//   // filter: blur(2px);
//   transform: rotate(150deg);
// }

.bgCir2 {
  top: 25%;
  right: 35%;
  width: 100px;
  height: 100px;
}
.bgCir3 {
  top: 35%;
  right: 25%;
  width: 50px;
  height: 50px;
}

// .input-section .input-div > div {
//   position: relative;
//   height: 45px;
// }

// .input-div > div > h5 {
//   position: absolute;
//   left: 10px;
//   top: 50%;
//   transform: translateY(-50%);
//   color: #999;
//   font-size: 18px;
//   transition: 0.3s;
// }

// .input-div:before,
// .input-div:after {
//   content: "";
//   position: absolute;
//   bottom: -2px;
//   width: 0%;
//   height: 2px;
//   background-color: #38d39f;
//   transition: 0.4s;
// }

// .input-div:before {
//   right: 50%;
// }

// .input-div:after {
//   left: 50%;
// }

// .input-div.focus:before,
// .input-div.focus:after {
//   width: 50%;
// }

// .input-div.focus {
//   border-bottom: 2px solid #38d39f;
// }

// .input-div.focus > div > h5 {
//   top: -5px;
//   font-size: 15px;
// }

// .input-div.focus > .i > i {
//   color: #38d39f;
// }

// .input-div > div > input {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   border: none;
//   outline: none;
//   background: none;
//   padding: 0.5rem 0.7rem;
//   font-size: 1.8rem;
//   color: #555;
//   font-family: "poppins", sans-serif;
// }

// .input-div.pass {
//   margin-bottom: 4px;
// }
.form {
  width: 300px;
  text-align: center;
  align-items: center;
}

a {
  // margin-top: 15px;
  display: block;
  text-align: right;
  text-decoration: none;
  color: #999;
  font-size: 1.2rem;
  transition: 0.3s;
}

a:hover {
  color: #38d39f;
}

.mdl-textfield__input {
  background-color: transparent !important;
}

.mdl-textfield__label {
  font-family: "poppins", sans-serif;
  color: #999 !important;
  //border-bottom: 2px solid #38d39f !important;
}

.mdl-textfield__input {
  color: #000 !important;
}

.signup-btn {
  display: inline-block;
  width: 45%;

  height: 50px;
  border-radius: 25px;
  outline: none;
  border: none;
  background-color: #ebecf0;
  background-size: 200%;
  font-size: 1.8rem;
  color: #555 !important;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 1rem 7px;
  cursor: pointer;
  transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: -5px -5px 20px var(--shadow-pri), 5px 5px 20px var(--shadow-sec);

  &:hover {
    box-shadow: -2px -2px 5px var(--shadow-pri), 2px 2px 5px var(--shadow-sec);
    color: #777;
  }

  &:active {
    box-shadow: inset 1px 1px 2px var(--shadow-sec),
      inset -1px -1px 2px var(--shadow-pri);
  }
}

.submit-btn {
  display: inline-block;
  width: 45%;
  height: 50px;
  border-radius: 25px;
  outline: none;
  border: none;
  background-color: #ebecf0;
  background-size: 200%;
  font-size: 1.8rem;
  color: #1baa8b !important;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 1rem 7px;
  cursor: pointer;
  transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: -5px -5px 20px var(--shadow-pri), 5px 5px 20px var(--shadow-sec);

  &:hover {
    box-shadow: -2px -2px 5px var(--shadow-pri), 2px 2px 5px var(--shadow-sec);
    color: #777;
  }

  &:active {
    box-shadow: inset 1px 1px 2px var(--shadow-sec),
      inset -1px -1px 2px var(--shadow-pri);
  }
}
.submit-btn:hover {
  background-position: right;
  color: #fff;
}
.signup-btn:hover {
  background-position: right;
  color: #fff;
}

@media screen and (max-width: 1050px) {
  .main-container {
    grid-gap: 5rem;
  }
}

@media screen and (max-width: 1000px) {
  .form {
    width: 290px;
  }

  .login-container h2 {
    font-size: 2.4rem;
    margin: 8px 0;
  }

  .img img {
    width: 400px;
  }
}

@media screen and (max-width: 900px) {
  .bgCir {
    top: 40%;
    width: 150px;
    height: 150px;
  }
  .WaveMob {
    display: inline;
  }
  .main-container {
    grid-template-columns: 1fr;
  }

  .side-image {
    display: none;
  }

  .Wavebg {
    display: none;
  }

  .login-container {
    justify-content: center;
  }
  .div {
    margin-left: 15px;

    //padding: 30px;
  }
}
